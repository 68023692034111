// Future user registration service

const register = (errorCb: any, successCb: any, data: any) => {
    console.log("Register", data);

    successCb({item: { Name: data.name }});
};

export default {
    register: register
}