import React, { Component } from 'react';
import './Page.css';
import Constants from './config/Constants';
import Nav from './Nav';
import UserRegistration from './UserRegistration';

interface Props {
  user: any;
}

class Home extends Component<Props, {}> {
  render() {
    return (
      <div className="page">
        <Nav user={this.props.user}/>
        <header className="spot">
          <figure className="spot-figure">
            <img src="https://picsum.photos/250/250/?random" alt="poster"/>
          </figure>
        </header>
        <section className="lead">
          <p>
            {Constants.appName} is here to help (TSX).
          </p>
        </section>
        <main className="cont">
          <UserRegistration user={this.props.user}/>
        </main>
      </div>
    );
  }
}

export default Home;
