import React, { Component } from 'react';
import User, { IUser } from './User';
import './Nav.css';

function UserKnown(props: IUser) {
  return (
    <nav className="navi">
      <a href="/">home</a>
      <span className="divider">&#9675;</span>
      <a href="/new">new</a>
      <span className="divider">&#9675;</span>
      <User user={props.user}/>
    </nav>
  );
}

function UserUnknown() {
  return (
    <nav className="navi">
      Already a beta user? <User />
    </nav>
  );
}

function UserUnknownAndHome() {
  return (
    <nav className="navi">
      <a href="/">home</a>
      &nbsp;|&nbsp;
      Already a beta user? <User />
    </nav>
  );
}

class Nav extends Component<IUser, {}> {

  render() {
    const showHome = window.location.pathname.length > 1;
    return this.props.user ?
      <UserKnown user={this.props.user} /> : showHome ? <UserUnknownAndHome /> : <UserUnknown />;
  }
}

export default Nav;
