const region = 'us-east-1';
const betaUserPoolId = 'us-east-1_LytS5i8eQ';
const betaUserPoolClientId = '57lnd1s6qtu9mnlsg1gnii1tub';
const prodUserPoolId = 'us-east-1_zyGTg4uPB';
const prodUserPoolClientId = '268mgmp7ofj3ir3hanbq8h5pi6';

const beta = {
  stage: "beta",
  appName: "Beta.Chronaticus",
  appDomain: window.location.host,
  cognito: {
    UserPoolId: betaUserPoolId,
    ClientId: betaUserPoolClientId,
    AppWebDomain: "chronaticus-beta.auth.us-east-1.amazoncognito.com"
  },
  amplify: {
    Auth: {
      region: region,
      identityPoolId: "us-east-1:12ccdbf9-80e7-4704-b88b-c234f039ff54",
      userPoolId: betaUserPoolId,
      userPoolWebClientId: betaUserPoolClientId
    }
  }
}

const prod = {
  stage: "prod",
  appName: "Chronaticus",
  appDomain: "chronaticus.com",
  cognito: {
    UserPoolId: prodUserPoolId,
    ClientId: prodUserPoolClientId,
    AppWebDomain: "chronaticus-prod.auth.us-east-1.amazoncognito.com"
  },
  amplify: {
    Auth: {
      region: region,
      identityPoolId: "us-east-1:136609bb-819d-4e90-8c4a-b2fac91fc45f",
      userPoolId: prodUserPoolId,
      userPoolWebClientId: prodUserPoolClientId
    }
  }
}

const where = window.location.host;
const config = where === prod.appDomain ? prod : beta;

export default config;
