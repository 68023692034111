import React, { Component } from 'react';
import Nav from './Nav';
import { IUser } from './User';
import './Page.css';

class New extends Component<IUser, {}> {
  render() {
    return (
      <div className="page">
        <Nav user={this.props.user}/>
        <header className="spot">
          <figure className="spot-figure spot-emoji">
            <span role="img" aria-label="sunrise">&#x1F305;</span>
          </figure>
        </header>
        <section className="lead">
          <p>
          200
          </p>
        </section>
        <main className="cont">
        You've reached New (TSX).
        </main>
      </div>
    );
  }
}

export default New;
