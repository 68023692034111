import React from 'react';
import './Spinner.css';

function Spinner(props: any) {
  return (
    <div className="spinner">
      <span role="img" aria-label="spinner">&#x2605;</span>
    </div>
  );
}

export default Spinner;
