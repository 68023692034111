import React, { Component } from 'react';
import AuthService from './services/AuthService'
import './User.css';

interface Props {
  user: any;
}

interface CognitoUser {
  'cognito:username': string;
}

export interface IUser {
  user?: CognitoUser
}

function UserKnown(props: { user: CognitoUser }) {
  console.log(props);
  return (<div className="user">
    <div className="user user-known">
      <span className="user-name">
        <a href={'/e/' + props.user['cognito:username']}>{props.user['cognito:username']}</a>
      </span>
      &nbsp;
      <button onClick={AuthService.signOut}>sign out</button>
    </div>
  </div>
  );
}

function UserUnknown() {
  return (<div className="user">
    <div className="user user-unknown">
      <button onClick={AuthService.signIn}>sign in</button>
    </div>
  </div>
  );
}

class User extends Component<IUser, {}> {
  render() {
    return this.props.user ? <UserKnown user={this.props.user} /> : <UserUnknown />;
  }
}

export default User;
