import React, { Component, SyntheticEvent } from 'react';
import Spinner from './Spinner'
import RegistrationService from './services/RegistrationService';
import './UserRegistration.css';

const defaultType = 'friend';

interface Props {
  user: {
    name: string;
  }
}

interface State {
  [index: string]: string;
}

// TODO: AA In the middle of this, just barely started
// transitioning to tsx.

class UserRegistration extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: '',
      name: props.user ? props.user.name : '',
      type: defaultType,
      status: props.user ? 'KNOWN' : 'DEFAULT'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  handleChange(event: any) {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();

    this.setState({
      status: 'PENDING'
    });

    RegistrationService.register(this.handleError.bind(this), this.handleSuccess.bind(this), this.state);
  }

  handleError(error: any) {
    console.error("[UserRegistration] Showed error to user");
    this.setState({
      status: 'ERROR',
      message: error
    });
  }

  handleSuccess(data: any) {
    console.log("[UserRegistration] Showed thank you message to user");
    console.log(data);
    this.setState({
      status: 'DONE',
      name: data.item.Name,
      message: data
    });
  }

  render() {
    console.log(`[Render] ${this.state.status}`);
    switch(this.state.status) {
      case 'ERROR':
      return (
        <div className="cont">
          <p className="registration-error">Error: {this.state.message}</p>
          <p>If this looks like a mistake or a transient issue, please <a href="/">try again</a>.</p>
        </div>
        )
      case 'DONE':
        return (
        <div className="cont">
          <p>Thanks for registering with us <b>{this.state.name}</b>!</p>
          <p>
            We'll keep track of you in our records.
          </p>
        </div>
        )
      case 'PENDING':
      return (
        <div className="cont">
          <Spinner />
        </div>
        )
      case 'KNOWN':
      return (
        <div className="cont">
          <p>Welcome back {this.state.name}.</p>
        </div>
        )
      default:
      return (
        <div className="cont">
          <p className="registration-text">
          Fill this form to let us know you're interested in Chronaticus.
          </p>
          <p>***</p>
          <form className="registration-form" onSubmit={this.handleSubmit}>
            <label>
              Name:
              <input name="name" type="text" value={this.state.name} onChange={this.handleChange} />
            </label>
            <label>
              Email:
              <input name="email" type="text" value={this.state.email} onChange={this.handleChange} />
              </label>
            <label>
              How did you hear about us?
              <select name="type" value={this.state.type} onChange={this.handleChange}>
                <option value={defaultType}>Friend</option>
                <option value="social-media">Social media</option>
                <option value="search-engine">Search engine</option>
                <option value="paper">Flyer or poster</option>
                <option value="other">Other</option>
              </select>
            </label>
            <div className="registration-button">
              <button onClick={this.handleSubmit}>Submit</button>
            </div>
          </form>
        </div>
      );
    }
  }
}

export default UserRegistration;
