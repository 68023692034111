import React, { Component } from 'react';
import Nav from './Nav';
import './Page.css';

interface Props {
  user: any;
  id: string;
}

class Entity extends Component<Props, {}> {
  render() {
    return (
      <div className="page">
        <Nav user={this.props.user}/>
        <header className="spot">
          <figure className="spot-figure spot-emoji">
            <span role="img" aria-label="sunrise">&#x1F305;</span>
          </figure>
        </header>
        <section className="lead">
          <p>
          200
          </p>
        </section>
        <main className="cont">
        {this.props.id}: You're an E (TSX).
        </main>
      </div>
    );
  }
}

export default Entity;
